import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';
import sheets from '../modules/sheets-module';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/sheets`, options)
    .then(response => {
      return {
        list: response.data
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/sheets/${id}`, options)
    .then(response => {
      return {
        sheet: response.data[0]
      };
    });
}

function add(sheet) {
  /*const payload = jsona.serialize({
    stuff: sheet,
    includeNames: null
  });*/
  //console.log(payload);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      //'Content-Type': 'application/vnd.api+json, multipart/form-data',
      //'Content-Type': 'multipart/form-data'
    }
  };

  return axios.post(`${url}/sheets`, sheet, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(sheet) {
  console.log('xxx');
  console.log(sheet);
  console.log(sheet.get('id'));
  //jsona.deserialize(sheet);
  /*const payload = jsona.serialize({
    stuff: sheet,
    includeNames: []
  });*/
  //console.log(payload);
  console.log('xxx--');
  console.log(sheet);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/json,multipart/form-data',
    }
  };

  return axios.post(`${url}/sheets/`+sheet.get('id'), sheet, options)
    .then(response => {
      console.log(response);
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/sheets/${id}`, options);
}

function filter(attribute,value, params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/sheets?filter[${attribute}]=${value}`, options)
    .then(response => {
      return {
        list: response.data
      };
    });
}

function filterwith(attribute,value, params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/sheets?filter[${attribute}]=${value}&include=tags,categories`, options)
    .then(response => {
      return {
        list: response.data
      };
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  filter,
  filterwith
};
