import qs from 'qs';
import axios from 'axios';
import Jsona, { JsonDeserializer } from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/plan-users`, options)
    .then(response => {
      return {
        list: response.data
      };
    });
}

function include(value, params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/plan-users?include=${value}`, options)
    .then(response => {
      return {
        list: response.data
      };
    });
}

function add(plan_user) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/plan-users`, plan_user, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/plan-users/${id}`, options);
}

export default {
  list,
  add,
  destroy,
  include
};

