import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/users`, options)
    .then(response => {
      return {
        list: response.data
      };
    });
}

function get(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.get(`${url}/users/${id}?include=roles,planUsers`, options)
    .then(response => {
      return {
        user: response.data[0]
      };
    });
}

function add(user) {
  /*const payload = jsona.serialize({
    stuff: user,
    includeNames: null
  });*/
  //console.log(payload);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.post(`${url}/users`, user, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function update(user) {
  /*const payload = jsona.serialize({
    stuff: user,
    includeNames: []
  });*/

  console.log(user);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  
  return axios.patch(`${url}/users/${user.id}`, JSON.stringify(user), options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.delete(`${url}/users/${id}`, options);
}

function upload(user, image) {
  const bodyFormData = new FormData();
  bodyFormData.append('attachment', image);

  return axios.post(`${url}/uploads/users/${user.id}/profile-image`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}

function filter(attribute,value, params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };
  return axios.get(`${url}/users?filter[${attribute}]=${value}`, options)
    .then(response => {
      return {
        list: response.data
      };
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  upload,
  filter
};

