import Vue from 'vue';
import Vuex from 'vuex';

import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import reset from "./modules/reset";
import roles from "./modules/roles-module";
import clients from "./modules/clients-module";
import permissions from "./modules/permissions-module";
import plans from "./modules/plans-module";
import plan_users from "./modules/plan-users-module";
import functionalities from "./modules/functionalities-module";
import sheets from "./modules/sheets-module";
import coupons from "./modules/coupons-module";
import tags from "./modules/tags-module";
import categories from "./modules/categories-module";
import authmodule from "./modules/auth-module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    profile,
    users,
    reset,
    roles,
    clients,
    permissions,
    plans,
    plan_users,
    functionalities,
    sheets,
    coupons,
    tags,
    categories,
    authmodule
  }
});
