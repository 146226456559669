import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function get() {
  return axios.get(`${url}/auth/me`)
    .then(response => {
      return {
        list: response.data,
        meta: response.data.meta
      };
    }).catch(function (error) {
      console.log('----------');
      console.log(error);
      localStorage.removeItem("vue-authenticate.vueauth_access_token");
      localStorage.removeItem("vue-authenticate.email");
    });
}

function update(profile) {

  const payload = jsona.serialize({
    stuff: profile,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/auth/me`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

export default {
  get,
  update
};
