import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import AuthLayout from '@/layout/dashboard/AuthLayout.vue';

// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

const Login = () =>
  import(/* webpackChunkName: "pages" */ '@/pages/Login.vue');
const Register = () =>
  import(/* webpackChunkName: "pages" */ '@/pages/Register.vue');
const PasswordReset = () =>
  import(/* webpackChunkName: "password" */ "@/pages/Password/Reset.vue");
const PasswordEmail = () =>
  import(/* webpackChunkName: "password" */ "@/pages/Password/Email.vue");

// Admin pages
const Dashboard = () => import(/* webpackChunkName: "dashboard" */"@/pages/Dashboard.vue");
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue");
const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue");
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue");
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue");
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue");
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue");

// Example pages
const UserProfile = () => import('@/pages/Examples/UserProfile.vue');
// User Management
//const ListUserPage = () => import("@/pages/Examples/UserManagement/ListUserPage.vue");
// User Management
const ListUserPage = () => import("@/pages/Users/UserManagement/ListUserPage.vue");
// User Management
const CreateUserPage = () => import("@/pages/Users/CreateUserPage.vue");
// User edit
const EditUserPage = () => import("@/pages/Users/EditUserPage.vue");

// Clientes
// Client Management
const ListClientPage = () => import("@/pages/Clients/ClientManagement/ListClientPage.vue");
// Client Management
const CreateClientPage = () => import("@/pages/Clients/CreateClientPage.vue");
// Client Management
const EditClientPage = () => import("@/pages/Clients/EditClientPage.vue");

//Roles
// Role management
const ListRolePage = () => import("@/pages/Roles/RoleManagement/ListRolePage.vue");

// Permission management
const ListPermissionPage = () => import("@/pages/Permissions/PermissionManagement/ListPermissionPage.vue");
const CreatePermissionPage = () => import("@/pages/Permissions/CreatePermissionPage.vue");


// Planes
const ListPlanPage = () => import("@/pages/Plans/PlanManagement/ListPlanPage.vue");
const CreatePlanPage = () => import("@/pages/Plans/CreatePlanPage.vue");
const AsignPlanPage = () => import("@/pages/Plans/AsignPlanPage.vue");
const FunctionPlanPage = () => import("@/pages/Plans/FunctionPlanPage.vue");
const AsignFunctionPlanPage = () => import("@/pages/Plans/AsignFunctionPage.vue");

// Laminas
const ListLaminasPage = () => import("@/pages/Laminas/LaminasManagement/ListLaminasPage.vue");
const CreateLaminasPage = () => import("@/pages/Laminas/CreateLaminasPage.vue");
const EditLaminasPage = () => import("@/pages/Laminas/EditLaminasPage.vue");

//Cupones
const CouponsPage = () => import("@/pages/Coupons/CouponsManagement/ListCouponsPage.vue");

let authPages = {
  path: "/",
  redirect: "/login",
  component: AuthLayout,
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: { middleware: guest }
    },
    {
      path: "/password/reset",
      name: "Password Reset",
      component: PasswordReset,
      meta: { middleware: guest }
    },
    {
      path: "/password/email",
      name: "Password Reset",
      component: PasswordEmail,
      meta: { middleware: guest }
    },
  ]
};

let examplesMenu = {
  path: "/examples",
  component: DashboardLayout,
  name: "Examples",
  children: [
    {
      path: "user-profile",
      name: "User Profile",
      components: { default: UserProfile },
      meta: { middleware: auth }
    },
    /*{
      path: "user-management/list-users",
      name: "List Users",
      components: { default: ListUserPage },
      meta: { middleware: auth }
    },*/
  ]
};

let usersMenu = {
  path: "/users",
  component: DashboardLayout,
  name: "Usuarios",
  children: [
    {
      path: "user-management/list-users",
      name: "List users",
      components: { default: ListUserPage },
      meta: { middleware: auth }
    },
    {
      path: "user-create",
      name: "Create Users",
      components: { default: CreateUserPage },
      meta: { middleware: auth }
    },
    {
      path: "user-edit",
      name: "Edit Users",
      components: { default: EditUserPage },
      meta: { middleware: auth }
    },
  ]
};

let clientsMenu = {
  path: "/clients",
  component: DashboardLayout,
  name: "Clientes",
  children: [
    {
      path: "client-management/list-client",
      name: "List Clients",
      components: { default: ListClientPage },
      meta: { middleware: auth }
    },
    {
      path: "client-create",
      name: "Create Client",
      components: { default: CreateClientPage },
      meta: { middleware: auth }
    },
    {
      path: "client-edit",
      name: "Edit Client",
      components: { default: EditClientPage },
      meta: { middleware: auth }
    }
  ]
};

let rolesMenu = {
  path: "/roles",
  component: DashboardLayout,
  name: "Roles",
  children: [
    {
      path: "role-management/list-roles",
      name: "List Roles",
      components: { default: ListRolePage },
      meta: { middleware: auth }
    },
  ]
}

let permissionMenu = {
  path: "/permissions",
  component: DashboardLayout,
  name: "Permissions",
  children: [
    {
      path: "permission-management/list-permissions",
      name: "List Permissions",
      components: { default: ListPermissionPage },
      meta: { middleware: auth }
    },
    {
      path: "permission-management/create-permissions",
      name: "Create Permissions",
      components: { default: CreatePermissionPage },
      meta: { middleware: auth }
    },
  ]
}

let plansMenu = {
  path: "/plans",
  component: DashboardLayout,
  name: "Planes",
  children: [
    {
      path: "plan-management/list-plans",
      name: "List plan",
      components: { default: ListPlanPage },
      meta: { middleware: auth }
    },
    {
      path: "plan-create",
      name: "Create plan",
      components: { default: CreatePlanPage },
      meta: { middleware: auth }
    },
    {
      path: "plan-asign",
      name: "Asignar plan",
      components: { default: AsignPlanPage },
      meta: { middleware: auth }
    },
    {
      path: "plan-function",
      name: "Funciones",
      components: { default: FunctionPlanPage },
      meta: { middleware: auth }
    },
    {
      path: "asign-plan-function",
      name: "Asignar Funciones",
      components: { default: AsignFunctionPlanPage },
      meta: { middleware: auth }
    }
    
  ]
};

let laminasMenu = {
  path: "/laminas",
  component: DashboardLayout,
  name: "Laminas",
  children: [
    {
      path: "lamina-management/listar-laminas",
      name: "Listar láminas",
      components: { default: ListLaminasPage },
      meta: { middleware: auth }
    },
    {
      path: "lamina-create",
      name: "Crear lámina",
      components: { default: CreateLaminasPage },
      meta: { middleware: auth }
    },
    {
      path: "lamina-edit",
      name: "Editar lámina",
      components: { default: EditLaminasPage },
      meta: { middleware: auth }
    }  
  ]
};

let couponsMenu = {
  path: "/coupons",
  component: DashboardLayout,
  name: "Coupons",
  children: [
    {
      path: "coupon-management/list-coupons",
      name: "Cupones",
      components: { default: CouponsPage },
      meta: { middleware: auth }
    },
  ]
}

const routes = [
  examplesMenu,
  authPages,
  usersMenu,
  clientsMenu,
  rolesMenu,
  permissionMenu,
  plansMenu,
  laminasMenu,
  couponsMenu,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { middleware: auth }
      },
      {
        path: "profile",
        name: "profile",
        component: Profile
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "maps",
        name: "maps",
        component: Maps
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "table-list",
        name: "table-list",
        component: TableList
      }
    ]
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
