import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function update(user) {

  console.log(user);

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };
  
  return axios.post(`${url}/auth/reset-password-admin/${user.id}`, user, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}


export default {
  update
};

