import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/auth/login",
  logoutUrl: "/auth/logout",
  registerUrl: "/register",
});

export default {
  state: {
    isAuthenticated: localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
  },

  actions: {
    login(context, payload) {
      return vueAuth
        .login(payload.user, payload.requestOptions)
        .then((response) => {
          console.log(response.data);
          localStorage.setItem('vue-authenticate.vueauth_access_token', response.data.token);
          localStorage.setItem('vue-authenticate.email', response.data[0].email);
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
          router.push({ path: "/dashboard" });
        });
    },

    register(context, payload) {
      return vueAuth
        .register(payload.user, payload.requestOptions)
        .then((response) => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
          router.push({ path: "/dashboard" });
        });
    },

    logout(context, payload) {
      return vueAuth.logout(payload.requestOptions).then((response) => {
        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated(),
        });

        localStorage.removeItem("vue-authenticate.vueauth_access_token");
        localStorage.removeItem("vue-authenticate.email");
        router.push({ name: "Login" });
      });
    },
  },
};
