<template>
  <div class="wrapper">
    <side-bar :background-color="backgroundColor">
      <template slot="links">
        <sidebar-link
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />
        <!--<sidebar-link
          to="#"
          :name="$t('sidebar.examples')"
          icon="fab fa-vuejs fa-2x"
        />
        <sidebar-link
          to="/examples/user-profile"
          :name="$t('sidebar.userProfile')"
          class="ml-5"
        />-->
        <sidebar-link to="#" :name="$t('Usuarios')" icon="fab fa-vuejs fa-2x" />
        <sidebar-link
          to="/users/user-management/list-users"
          :name="$t('Empleados')"
          class="ml-5"
        />
        <sidebar-link
          to="/clients/client-management/list-client"
          :name="$t('Clientes')"
          class="ml-5"
        />
        <sidebar-link
          to="#"
          :name="$t('Roles y Permisos')"
          icon="fab fa-vuejs fa-2x"
        />
        <sidebar-link
          to="/roles/role-management/list-roles"
          :name="$t('Role Management')"
          class="ml-5"
        />
        <sidebar-link
          to="/permissions/permission-management/list-permissions"
          :name="$t('Permissions Management')"
          class="ml-5"
        />

        <sidebar-link to="#" :name="$t('Planes')" icon="fab fa-vuejs fa-2x" />
        <sidebar-link
          to="/plans/plan-management/list-plans"
          :name="$t('Listar')"
          class="ml-5"
        />
        <sidebar-link
          to="/plans/plan-asign"
          :name="$t('Asignar Usuario')"
          class="ml-5"
        />
        <sidebar-link
          to="/plans/plan-function"
          :name="$t('Funciones')"
          class="ml-5"
        />
        <sidebar-link
          to="/plans/asign-plan-function"
          :name="$t('Asignar Funciones')"
          class="ml-5"
        />

        <sidebar-link to="#" :name="$t('Laminas')" icon="fab fa-vuejs fa-2x" />
        <sidebar-link
          to="/laminas/lamina-management/listar-laminas"
          :name="$t('Listar')"
          class="ml-5"
        />
        <sidebar-link
          to="/laminas/lamina-create"
          :name="$t('Crear')"
          class="ml-5"
        />

        <sidebar-link to="#" :name="$t('Cupones')" icon="fab fa-vuejs fa-2x" />
        <sidebar-link
          to="/coupons/coupon-management/list-coupons"
          :name="$t('Crear y Listar')"
          class="ml-5"
        />

        <!--<sidebar-link
          to="/notifications"
          :name="$t('sidebar.notifications')"
          icon="tim-icons icon-bell-55"
        />-->
        <!--<sidebar-link
          to="/profile"
          :name="$t('sidebar.userProfile')"
          icon="tim-icons icon-single-02"
        />-->
      </template>
    </side-bar>
    <sidebar-share :background-color.sync="backgroundColor"> </sidebar-share>
    <div class="main-panel" :data="backgroundColor">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
//import SidebarShare from "./SidebarSharePlugin";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    //SidebarShare,
  },
  data() {
    return {
      backgroundColor: "primary",
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
